<template>
  <v-row justify="center">
    <v-dialog v-model="shower" persistent max-width="500">
      <v-card scrollable persistent>
        <v-card-title>Add Relation Outbox<v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">device_hub</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout row wrap align-center justify-center>
            <v-flex xs11 lg11 ma-5>
              <v-card>
                <v-container fluid>
                  <v-row align="center">
                    <v-col cols="12">
                      <v-layout row wrap justify-center>
                        <v-flex lg10 xs10 class="text-center">
                          <v-text-field
                            label="NameRelation"
                            v-model="namerelation"
                            persistent-hint
                            outlined
                            dense
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap justify-center>
                        <v-flex lg10 xs10 class="text-center">
                          <v-select
                            label="Filename"
                            :items="statusrelation"
                            v-model="relation"
                            persistent-hint
                            outlined
                            dense
                          ></v-select>
                        </v-flex>
                      </v-layout>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            @click="$emit('closedialog'),relation='', namerelation=''"
          >{{$t('uploadfolder.buttonclose') }}</v-btn>
          <v-btn @click="apiAdd()" :color="color.theme" dark>{{$t('changeName.submit')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  props: ["show", "outboxdata"],
  data() {
    return {
      relation: "",
      namerelation: "",
      statusrelation: [],
      dialog: false,
      fileoutbox: [],
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          this.loaddata();
        }
        return this.show;
      },
    },
  },
  methods: {
   async apiAdd() {
      let newdata = "";
      let payload;
      if (this.namerelation === "" && this.relation === "") {
        Toast.fire({
          icon: "error",
          title: "Please enter name and select relation",
        });
      } else if (this.relation === "") {
        Toast.fire({
          icon: "error",
          title: "Please Select Relation",
        });
      } else if (this.namerelation === "") {
        Toast.fire({
          icon: "error",
          title: "Please Enter Name ",
        });
      } else {
        for (let i = 0; i < this.fileoutbox.length; i++) {
          if (this.relation === this.fileoutbox[i].filename) {
            newdata = this.fileoutbox[i].inbox_id;
          }
        }
        payload = {
          inbox_root: newdata,
          account_id: this.dataAccountId,
          relation_name: this.namerelation,
        };
        console.log("payload", payload);
        console.log(this.relation);
        console.log(this.fileoutbox);
        let auth = await gbfGenerate.generateToken();
       await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/insert_relation_inbox",
            payload, { headers: { Authorization: auth.code },},
          )
          .then((response) => {
            if (response.data.status === "OK") {
              Toast.fire({
                icon: "success",
                title: "Success",
              });
              this.$emit("closedialog");
              this.$emit("loaddata");
              this.namerelation = "";
              this.relation = "";
            } else {
              Toast.fire({
                icon: "error",
                title: "Error",
              });
            }
          });
      }
    },
    loaddata() {
      let payload;
      this.fileoutbox = [];
      payload = {
        limit: "",
        offset: "",
        sender_id: [this.dataAccountActive.business_info["business_id"]],
        sender_type: "2",
        filename: "",
        fileextension: "",
        doc_no_start: "",
        doc_no_end: "",
        doc_date_start: "",
        doc_date_end: "",
        doc_type: "",
        cre_dtm_start: "",
        cre_dtm_end: "",
        sender_name: "",
        system: this.$route.query.systemid,
        sellerTaxid: "",
        sellerBranchid: "",
        receiver_name: "",
        receiverTaxid: "",
        receiverBranchid: "",
        status: "",
      };
      console.log("payloadจ้า", payload);

      this.axios
        .post(
          process.env.VUE_APP_SERVICE_GET_OUTBOX +
            "/api/get_file_outbox_child",
          payload
        )
        .then((response) => {
          if (response.data.status === "OK") {
            // console.log("response------",response);
            this.fileoutbox = response.data.result.data;
            this.checkstatusrelation();
          }
          // console.log("fileoutbox--------",this.fileoutbox);
        });
    },
    checkstatusrelation() {
      this.statusrelation = [];
      for (let i = 0; i < this.fileoutbox.length; i++) {
        if (
          this.fileoutbox[i].relation_status === 2 &&
          this.fileoutbox[i].status === "active"
        ) {
          this.statusrelation.push(this.fileoutbox[i].filename);
        }
      }
      // console.log("statusrelation", this.statusrelation);
    },
  },
};
</script>
 <style>
#thiscontainer {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
</style>